export const ENERGO_PARTNERS = [
    {
        id: 479,
        title: "E.ON",
        seoName: "eon",
    },
    {
        id: 482,
        title: "ČEZ",
        seoName: "cez",
    },
    {
        id: 494,
        title: "MND",
        seoName: "mnd",
    },
    {
        id: 472,
        title: "innogy",
        seoName: "rwe",
    },
    {
        id: 484,
        title: "Centropol",
        seoName: "centropol-energy",
    },
    {
        id: 532,
        title: "Nano Energies",
        seoName: "nano-energies-trade",
    },
];

export const VEHICLE_INSURANCE_PARTNERS = [
    {
        id: 10,
        title: "Allianz",
        seoName: "allianz-pojistovna",
    },
    {
        id: 5,
        title: "ČSOB Pojišťovna",
        seoName: "csob-pojistovna",
    },
    {
        id: 4,
        title: "Direct Pojišťovna",
        seoName: "direct-pojistovna",
    },
    {
        id: 7,
        title: "Generali Česká pojišťovna",
        seoName: "generali-ceska-pojistovna",
    },
    {
        id: 9,
        title: "Kooperativa",
        seoName: "kooperativa-pojistovna",
    },
    {
        id: 3,
        title: "ČPP",
        seoName: "ceska-podnikatelska-pojistovna",
    },
];

export const FINANCE_PARTNERS = [
    {
        id: 1,
        title: "Česká spořitelna",
        seoName: "ceska-sporitelna",
    },
    {
        id: 2,
        title: "Home Credit",
        seoName: "home-credit",
    },
];
